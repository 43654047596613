<template>
	<v-layout column style="min-height: 200px; position: relative">
		<v-layout
			v-if="loading"
			align-center
			class="w-loading-container"
			justify-center
			style="height: 100%; min-height: 200px; position: absolute; width: 100%; z-index: 1"
		>
			<v-progress-circular color="primary" indeterminate />
		</v-layout>
		<w-section v-if="application && application.is_importable" fill-height :title="$t('application.import_section')">
			<v-flex shrink>
				<ImportFormApplicationVendor ref="importForm" :readonly="readonly" :value="application" />
			</v-flex>
		</w-section>
		<w-section fill-height :title="$t('company.list')">
			<v-flex shrink>
				<ApplicationVendorAssigner
					v-if="!loading"
					:application-id="application?.catalog_application_id"
					:application-name="application?.title"
					:params="applicationFields"
					:readonly="readonly"
					:subscription-id="application?.id"
					@loaded ="onVendorLoaded"
				/>
			</v-flex>
		</w-section>
		<FixAccountingFirmApplicationDialog ref="fixDialog" v-model="fixDialog" :application="application" :fields="fixParameters" />
	</v-layout>
</template>

<script>
import ApplicationsModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationsModuleGuard'
export default {
	name: 'ApplicationVendorAssignment',
	components: {
		ApplicationVendorAssigner: () => ({
			component: import('@/components/Applications/ApplicationVendorAssigner')
		}),
		FixAccountingFirmApplicationDialog: () => ({
			component: import('@/components/Applications/FixAccountingFirmApplicationDialog')
		}),
		ImportFormApplicationVendor: () => ({
			component: import('@/components/Applications/ImportFormApplicationVendor')
		})
	},
	mixins: [ApplicationsModuleGuard],
	props: {
		application: {
			default: null,
			required: false,
			type: Object
		},
	},
	data: function () {
		return {
			applicationFields: [],
			fixDialog: false,
			fixParameters: null,
			loading: false,
			readonly: false
		}
	},
	mounted: function() {
		this.load()
	},
	methods: {
		load: function () {
			this.loading = true
			
			this.loadParameters().finally(() => {
				this.loading = false
			})
		},
		loadParameters: function () {
			if (!this.application?.id) {
				this.readonly = false
				this.applicationFields.splice(0, this.applicationFields)
				return Promise.resolve()
			}

			return this.service.listApplicationVendorParameters(this.accountingFirmId, this.application.id)
				.then(params => {
					this.readonly = false
					if (Array.isArray(params)) {
						this.applicationFields = params.map(field => {
							field.label = this.$t(field.label)
							return field
						})
					} else {
						this.applicationFields = []
					}
				})
				.catch(error => {
					this.readonly = true

					if (error?.name != 'InvalidParentSubscriptionException' || error.parameters.length == 0) {
						return
					}

					this.fixParameters = error.parameters
					this.fixDialog = true

					return new Promise((resolve, reject) => {
						this.$refs.fixDialog.$once('close', function (eventValue) {
							this.fixDialog = false
							this.fixParameters = null
							if (eventValue) {
								return resolve()
							} else {
								return reject('Cancelled')
							}
						}.bind(this))
					})
						.then(() => {
							this.loadParameters()
						})
				})
		},
		onVendorLoaded: function () {
			this.$emit('loaded')
		}
	}
}
</script>
